/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.search {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
}

.page {
  background: #eeeeee;
}

.navbar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 15px;
  z-index: 5;
  height: 55px;
  background-color: white;
}

.headbar {
  width: 100%;
  text-align: center;
  padding-top: 15px;
}

.backbar {
  /*flex: 0;
  border-top: solid 1px var(--adm-color-border);
  position: fixed;
  top: 0; */
  /*width: 100%;
  padding: 10px 10px 0px 10px;*/
  height: 60px;
  background-color: white;
}

.card-title {
  margin-top: 2em;
}

.card-title-content {
  width: 130px;
  height: 130px;
}

.category {
  display: flex;
  flex-direction: column;
}

.card-category {
  margin-top: 2em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.card-category-content {
  height: 70px;
  width: 90px;
  /*max-width: 100px;
  min-width: 50px;*/
  text-align: center;
  overflow-wrap: break-word;
}

.card-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  padding-bottom: 90px;
}

.categories {
  width: 100%;
}


.card-item {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-item-article {
  font-size: 14px;
  font-weight: bold;
  color: #1677ff;
}

.card-item-producer {
  font-size: 14px;
  font-weight: bold;
  color: #1677ff;
  text-align: right;
}

.card-item-name {
  font-size: 16px;
  line-height: 1.1;
}

.card-item-button {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-item-price {
  text-align: right;
}

.card-item-price-quantity {
  font-size: 16px;
  font-weight: bold;
}

.card-item-price-value {
  font-size: 22px;
  font-weight: bold;
}

.page-header {
  margin: 1em;
  font-size: 21px;
  font-weight: bold;
}

.input-quantity {
  border-style: solid;
  border-width: 1px;
  border-color: #1677ff;
  border-radius: 7px;
  padding: 5px;

  max-width: 50px;
}